import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Turtlebeach = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #5E5943, #7D7654, #978E65, #7D7654, #5E5943 )",
        icons: "#c5af71",
        navClass: "turtlebeach",
      }}
      seo={{
        title: "Turtle Beach",
        headerTitle: "turtlebeach",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/turtlebeach_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/rtv-euro-agd/",
      }}
    >
      <PortfolioHeader className="img_turtle" name="turtlebeach" height="720" />
      <section className="container-fluid turtlebeach_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Turtle Beach</h1>
              <ul>
                <li>Website</li>
                <li className="first">Logo</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                We created a microsite to promote the sales of Turtle Beach
                headphones in the RTV EURO AGD network. In addition, we ran a
                marketing campaign on Facebook and thematic portals.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="turtlebeach_section_3">
        <div className="laptop-wrapper">
          <img
            className="img-fluid pendrive"
            src={require("../../../assets/img/portfolio/pendrive_tb.png")}
            alt=""
          />
          <img
            className="img-fluid headphones"
            src={require("../../../assets/img/portfolio/headphones_tb.png")}
            alt=""
          />
          <div className="scroll_wrapper">
            <div className="img_wrapper">
              <img
                className="screen"
                src={require("../../../assets/img/portfolio/turtlebeach_page.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Turtlebeach;
